<template>
  <div>
    <UserSingleCard showLogo>
      <template v-slot:title>
        <p class="login-title">会員登録（無料）</p>
      </template>

      <template v-slot:contents>
        <p class="login-email-title">メールアドレス</p>
        <input
          class="email"
          type="email"
          pattern="^[a-zA-Z0-9.!#$&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$"
          v-model="email"
        />

        <p class="login-password-title">パスワード</p>
        <input class="password" type="password" v-model="password"/>

        <p class="login-password-title">パスワード(確認用)</p>
        <input class="password" type="password" v-model="passwordConfirm"/>

        <div :style="{ marginTop: '16px', textAlign: 'left' }">
          <b-form-checkbox v-model="confirmPolicy">
            <span class="user-guideline-text" @click="$_onClickUserPolicyLink">利用規約</span>に同意
          </b-form-checkbox>
        </div> 
      </template>

      <template v-slot:button>
        <WideButton
          class="login-button"
          :label="'会員登録'"
          :disabled="!canSignUp"
          @click="$_signUp"
        />
      </template>
    </UserSingleCard>

    <!-- user guideline modal-->
    <b-modal ref="user-guideline-modal" scrollable hide-header body-class="overwrite-modal-body">
      <GuidelineContents />
      <template #modal-footer="{ ok, cancel }">
        <div class="user-guideline-modal-footer">
          <b-button size="sm" variant="info" @click="() => {
            confirmPolicy = true
            ok()
          }">
            同意
          </b-button>
          <b-button size="sm" variant="dark" @click="() => {
            confirmPolicy = false
            cancel()
          }">
            キャンセル
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import WideButton from '@/components/atoms/common/AppWideButton'
import UserSingleCard from '@/templates/user/AppUserSingleCard'
import { signUpApp } from '@/helper/auth'
import { showInfoPopupAlert, showErrorPopupAlert } from '@/helper/common'
import GuidelineContents from '@/components/organisms/user/AppUserUserGuidelineContents'

/**
 * FIXME: pageに具体的なコンポーネントの配置を記述するのではなく、templateに移すこと
 */
export default {
  name: 'TheSignUp',
  components: {
    UserSingleCard,
    WideButton,
    GuidelineContents
  },
  data() {
    return {
      email: '',
      password: '',
      passwordConfirm: '',
      confirmPolicy: false
    }
  },
  mounted: function () {
    this.$store.dispatch('loadingMask/hideLoadingMask')
  },
  computed: {
    canSignUp() {
      return (
        this.email &&
        this.password &&
        this.password === this.passwordConfirm &&
        this.confirmPolicy
      )
    }
  },
  methods: {
    $_signUp: async function () {
      try {
        this.$store.dispatch('loadingMask/showLoadingMask')
        await signUpApp(this.email, this.password)
        showInfoPopupAlert(
          `${this.email} へ認証メールを送信しました。メールに記載されているリンクへアクセスしてください。`,
          true,
          {},
          () => this.$router.push('/')
          // () => this.$router.push('/user/login')
        )
      } catch(e) {
        const errorMessage = this.$_getErrorMessage(e)
        showErrorPopupAlert(errorMessage)
      } finally {
        this.$store.dispatch('loadingMask/hideLoadingMask')
      }
    },
    $_getErrorMessage: function (error) {
      if (error.message.includes('Password should be at least 6 characters')) {
        return 'パスワードは6文字以上の英数字を設定してください'
      } else if (error.message.includes('invalid-email')) {
        return 'メールアドレス/パスワードが不正です'
      } else if (error.message.includes("email-already-in-use")) {
        return "入力されたメールアドレスは登録済みです。"
      } else if (error.message.includes("wrong-password")) {
        return "パスワードが間違っています。"
      } else {
        return `エラーが発生しました。${error.message}`
      }
    },
    $_onClickUserPolicyLink: async function () {
      // Sweetalert2のポップアップを検討したが、コンテンツにコンポーネントを
      // 指定する方法がうまくいかないので b-modal を使用
      this.$refs['user-guideline-modal'].show()
    }
  },
}
</script>

<style lang="scss">
input {
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  border: 1px solid gray;
  border-radius: 4px;
}
.login-title {
  margin: 16px 0;
}
.login-email-title {
  text-align: left;
  margin-bottom: 8px;
  font-size: 12px;
}
.email {
  margin: 0;
}
.login-password-title {
  text-align: left;
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 12px;
}
.password {
  margin: 0;
}
.login-button {
  margin-top: 32px;
}
.forgot-password {
  color: #707070;
  text-decoration: underline;
  cursor: pointer;
}
.user-guideline-text {
  color: #12B6D4;
  text-decoration: underline;
}

/* user guideline modal */
.overwrite-modal-body {
  color: black;
  .app-user-user-guideline-contents {
    .user-guideline-title {
      padding: 12px 20px 0 20px;
      box-shadow: none;
    }
  }
}
.user-guideline-modal-footer {
  width: 100%;
  display: flex;
  column-gap: 8px;
  justify-content: center;
  align-items: center;
  button {
    width: 100px;
  }
}
</style>
